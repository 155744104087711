import React, { useState } from "react";
import { graphql } from "gatsby";
import moment from "moment";
import ImageGallery from 'react-image-gallery';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Share from "../components/share";


export default function ProjectPost({ data }) {

  const project = data.contentfulProjects;
  const images = [
    { original: project.featureImage.fluid.src, thumbnail: project.featureImage.fluid.src },
    ...project.images.map(image => ({
      original: image.fluid.src,
      thumbnail: image.fluid.src,
    }))];

  const siteurl = data.contentfulSiteInformation.siteUrl + "/";
  const twiteerhandle = data.contentfulSiteInformation
    .twiteerHandle;
  const socialConfigss = {
    site: {
      siteMetadata: { siteurl, twiteerhandle }
    },
    title: project.title,
    slug: project.slug
  };

  return (<Layout>
    <SEO
      title={project.title}
      keywords={[
        `Paige Marie Cox`,
        `Interior Designer`,
        `Consultant`,
        `${project.title}`
      ]}
    />
    <div className="site-container project-post">
      <div className="container">
        {project.featureImage ? (
          <ImageGallery items={images} />
        ) : (
          <div className="no-image"></div>
        )}

        <div className="details">
          <h1 className="title">{project.title}</h1>
          <span className="date">
            <i className="fas fa-calendar-alt"></i>{" "}
            {moment(project.createdAt).format("LL")}
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: project.description.childMarkdownRemark.html
            }}
          />
        </div>
        <Share
          socialConfig={{
            ...socialConfigss.site.siteMetadata.twiteerhandletitle,
            config: {
              url: `${siteurl}${socialConfigss.slug}`,
              title: `${socialConfigss.title}`
            }
          }}
        />
      </div>
    </div>
  </Layout>)
}

export const pageQuery = graphql`
  query SinglePostQuery($slug: String!) {
    contentfulProjects(slug: { eq: $slug }) {
      id
      title
      slug
      images {
        fluid {
          src
        }
      }
      featureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      createdAt
    }
    contentfulSiteInformation {
      siteUrl
      twiteerHandle
    }
  }
`;
